import Contato_form from "../components/Contato_form";

function Contato() {
  return (
    <main className="main--contato">
      <div className="content">


      <section className="justify-content-center page-form" id="section19">
        <h2 className="h2 fontcolor-primary" data-aos="zoom-out-down" data-aos-duration="800"><b>Fale com nosso time</b></h2>
        <p className="fontcolor-opacity">Entre em contato agora mesmo com nosso time de especialistas e entenda as vantagens e benefícios que o Sales Journey pode trazer ao seu negócio.</p>

        <Contato_form />

      </section>

      </div>
    </main>
  );
}

export default Contato;
