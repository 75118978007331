import Navheader from "../menus/Navheader";

function Header() {
  return (
    <div className="Header" data-aos="fade-down-right" data-aos-duration="1000">
      <header className="App-header">
        <div className="content">

        <Navheader />

        </div>
      </header>
    </div>
  );
}

export default Header;
