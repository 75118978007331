function Politica() {
  return (
    <main className="main--politica">
      <div className="content">


      <section className="justify-content-center page-text" id="section25">
        <h2 className="h2 fontcolor-primary" data-aos="zoom-out-down" data-aos-duration="800"><b>Política de privacidade.</b></h2>

<p><b>Esta Política de Privacidade descreve as condutas e os procedimentos adotados pelo SALES JOURNEY para a coleta, armazenamento, uso, tratamento, compartilhamento e eliminação de Informações dos usuários obtidas junto a Plataforma SALES JOURNEY. <br /><br />

O usuário, neste ato, declara que fez a leitura completa e atenta das regras deste documento e dos Termos de Uso, estando plenamente ciente conferindo assim sua livre e expressa concordância com os termos aqui estipulados. Caso não esteja de acordo com estas Políticas de Privacidade, deverá descontinuar o seu acesso a Plataforma.</b><br /><br />

I. Informações Coletadas<br /><br />

O SALES JOURNEY realizará a coleta de dados que o usuário da Plataforma, voluntariamente, fornecer para seu acesso a Plataforma e registros de acesso das informações geradas quando o usuário ou um visitante acessar ou utilizar a Plataforma SALES JOURNEY.<br /><br />

O SALES JOURNEY declara que realiza a coleta e tratamento dos dados através dos canais abaixo relacionados:<br /><br />

(a) Dados fornecidos voluntariamente pelo titular ou seu representante para cadastro da Conta e contratação dos Serviços: O SALES JOURNEY promove a coleta e o tratamento de dados pessoais dos usuários conforme sejam necessários para o seu cadastramento na Plataforma, tais como nome, endereço de e-mail, contato telefônico e dados financeiros, tais como relacionados aos meios de pagamento utilizados para a contratação de um Plano de serviços;<br /><br />

(b) Informações coletadas durante a utilização da Plataforma: O SALES JOURNEY coleta e trata ainda dados relacionadas a:<br /><br />

(i) Dados de acesso e utilização: O SALES JOURNEY poderá coletar e tratar dados do usuário relacionados ao seu acesso e utilização da Plataforma e dos Serviços, tais como: tipo de plano contratado; informações do usuário com os Serviços, assim como a data e hora de qualquer acesso, inclusive os Serviços utilizados, o Conteúdo publicado na Conta do Cliente, a configuração e alteração na configuração dos Serviços, e pode incluir informações relacionados ou resultantes da utilização de Software de terceiros.<br /><br />

(ii) Dados técnicos: O SALES JOURNEY poderá coletar e tratar certos dados técnicos do usuário e de sua interação com a Plataforma, tais como informações de URL, dados de cookies, o endereço IP do usuário, geolocalização, o tipo de dispositivo utilizado para acessar ou conectar-se á Plataforma, identificação do dispositivo, atributos do dispositivo, tipo de ligação á rede (por exemplo, Wi-Fi, 3G, LTE, Bluetooth) e fornecedor de rede, desempenho da rede  e do dispositivo, tipo de navegador, idioma, informações que permitam a gestão dos direitos digitais, sistema operativo e versão do SALES JOURNEY.<br /><br />

O SALES JOURNEY não é responsável pela precisão, veracidade ou falta dela nas informações que o usuário prestar ao SALES JOURNEY ou pela sua desatualização, sendo de responsabilidade do usuário prestá-las com exatidão ou atualizá-las.<br /><br />

II. Finalidade<br /><br />

O SALES JOURNEY promoverá o tratamento dos dados coletados na forma do item I acima, em conformidade com a legislação brasileira de proteção de dados e estritamente com a finalidade de:<br /><br />

Fornecer, personalizar e melhorar a experiência do usuário com os Serviços oferecidos pela Plataforma, e com outros serviços e produtos fornecidos pelo SALES JOURNEY, (por exemplo, ao fornecer serviços personalizados, individualizados ou adaptados a sua localização ou interesse, recomendações e funcionalidades) Compreender de que forma o usuário acessa os Serviços, a fim de garantir a sua funcionalidade técnica, desenvolver novos produtos e serviços e analisar a utilização da Plataforma pelo usuário, incluindo a sua interação com aplicativos, produtos e serviços que são disponibilizados, oferecidos ou conectados através da Plataforma;<br /><br />

Permitir a execução das obrigações contratuais estabelecidas pelo SALES JOURNEY em face do usuário, incluindo prestação de serviços de atendimento, suporte, treinamento, hospedagem de dados, geração de relatório, etc...;<br /><br />

Processar o faturamento e outros assuntos relacionados a gestão do contrato;<br /><br />

Prover a autenticação do usuário e outras medidas para evitar a ocorrência de fraudes contra o usuário, a Plataforma e terceiros;<br /><br />

Enviar notificações e avisos sobre os Serviços;<br /><br />

Enviar comunicações ao usuário, diretamente ou através de parceiros, para fins de: marketing, pesquisa, participação em concursos, quis, promoções através de e-mails, notificações ou outras mensagens, de acordo com quaisquer autorizações eventualmente transmitidas pelo usuário (por exemplo, através da página de Definições de conta do usuário); e Fornecer ao usuário funcionalidades, atualizações, políticas de preços e de contratação, publicidade ou outro conteúdo baseado na localização e nos interesses específicos do usuário.<br /><br />

O consentimento que o usuário fornece para as finalidades de uso dos dados é coletado de forma individual, clara, específica e legítima.<br /><br />

III. Segurança da Informação<br /><br />

O SALES JOURNEY armazenará as informações coletadas na Plataforma em servidores próprios ou por ela contratados com o compromisso de cumprir as determinações de confidencialidade neste sentido:<br /><br />

a) O SALES JOURNEY adota as medidas tecnológicas adequadas a atender aos padrões legalmente requeridos para preservar a privacidade dos dados coletados em sua Plataforma, tais como:<br /><br />

(i) O SALES JOURNEY possui proteção contra o acesso não autorizado de terceiros a seus sistemas;<br /><br />

(ii) O SALES JOURNEY controla o acesso as informações coletadas por empregados e profissionais terceirizados que tenham se comprometido formalmente a manter seu sigilo e seu acesso é orientado pelos princípios da proporcionalidade, necessidade e relevância, objetivando a preservação da privacidade de acordo com esta Política; e<br /><br />

b) O SALES JOURNEY envidará seus melhores esforços para preservar a privacidade dos dados do usuário. Entretanto, nenhuma solução é totalmente segura e o SALES JOURNEY não pode garantir integralmente que todas as informações que trafegam na Plataforma não sejam alvo de acessos não autorizados perpetrados por meio de métodos desenvolvidos para obter informações de forma indevida. Por esse motivo, o usuário também deverá tomar as medidas apropriadas para a proteção de suas Informações, inclusive, mas sem se limitar, a preservação do sigilo de todos os Dados de Acesso.<br /><br />

IV. Compartilhamento<br /><br />

(a) O SALES JOURNEY não compartilhará os dados do usuário com terceiros sem anuência do usuário, exceto (i) conforme necessário para atender a instruções e requerimento do usuário; (ii) na medida necessária para viabilizar a funcionalidade dos Serviços SALES JOURNEY contratados, observados os itens (b) e (c) abaixo; (iii) para atender ordens judiciais nos termos da legislação em vigor, ou em processos judiciais para estabelecer ou exercer seus direitos legais ou empreender defesa contra ações judiciais; (iv) quando acreditar de boa-fé ser necessário compartilhar as informações com o objetivo de investigar, impedir ou adotar medidas relativas a atividades ilegais, suspeitas de fraude, situações envolvendo possíveis ameaças á segurança física de qualquer pessoa, ou violação aos Termos e Condições de Uso do SALES JOURNEY; (v) em caso de venda dos ativos do SALES JOURNEY, em função de mudança em seu controle ou em preparação para qualquer desses eventos (sendo que os adquirentes terão o direito de continuar a utilizar as informações conforme esta Política de Privacidade); ou (vi) com os outros objetivos, através de procedimentos especificados e autorizados pela lei.<br /><br />

(b) A Plataforma SALES JOURNEY e seus Serviços podem: (i) ser acessados por meio de diversos aplicativos, sistemas operacionais e plataformas (e.g., Windows, IOS, etc.), os quais possuem suas próprias e independentes políticas de privacidade e de tratamento de informações e, que, portanto, devem ser avaliadas e aceitas pelo usuário, conforme seu próprio critério; (ii) oferecer alguns Serviços que podem ser acessados por meio de Software de Terceiros em território nacional ou estrangeiro, regulados por suas próprias termos de uso e política de privacidade, devendo o usuário avaliá-las antes da contratação do Serviço em questão. Não obstante o previsto no item (d) abaixo, o SALES JOURNEY não terá qualquer responsabilidade, direta ou indireta, sobre as políticas de privacidade e termos de uso desses terceiros, tampouco sobre eventuais danos ou prejuízos que o usuário possa vir a sofrer em decorrência de culpa ou dolo dos referidos fornecedores dos Softwares de Terceiro. Caso o usuário não esteja de acordo com a política de privacidade praticada pelos fornecedores de Softwares de Terceiros, caberá ao usuário deixar de contratar ou de utilizar os Serviços por ele providos.<br /><br />

(c) O SALES JOURNEY poderá ainda compartilhar as informações coletadas com prestadores de serviços contratados pelo SALES JOURNEY, tais como empresas de processamento de dados (operadores), sistemas de processamento (serviços de computação em nuvem), consultoria em tecnologia da informação, serviços de telecomunicação, serviços de entrega, serviços de publicidade e marketing, organização de eventos, serviços de faturamento de créditos (financeiros).<br /><br />

(d) Nos casos previstos nos itens (b) e (c) acima, o SALES JOURNEY compromete-se a exigir dos fornecedores de Software de Terceiros e dos prestadores de serviços por ela contratados que se comprometam com as obrigações de confidencialidade e conformidade, de acordo com as leis de proteção de dados vigentes, em especial com a Lei nº 13.709/2018, por meio de instrumento contratual ou em declaração específica com força obrigacional.<br /><br />

(e) No caso de o SALES JOURNEY vir a ser obrigada a compartilhar dados pessoais dos usuários em decorrência de ordem emanada de autoridade judicial, policial ou administrativa, o SALES JOURNEY assegura que apenas revelará tais dados após a validação da ordem emitida junto aos órgãos competentes.<br /><br />

V.Retenção, Alteração e Eliminação das Informações.<br /><br />

Com exceção das hipóteses aqui previstas, os dados coletados pelo SALES JOURNEY por meio da Plataforma serão automaticamente excluídos dos servidores do SALES JOURNEY, depois de terem sido utilizados para os fins aos quais foram coletados.<br /><br />

No caso de encerramento de Conta, mediante a rescisão dos Termo de Condições de Uso do SALES JOURNEY, essa removerá de seu banco de dados os de todos os usuários relacionados a conta encerrada, mantendo apenas os dados que sejam necessários ao cumprimento das obrigações legais do SALES JOURNEY, para satisfazer exigências regulatórias, sanar divergências com o usuário, evitar fraudes e o uso indevido da Plataforma e dos dados e/ou para garantir o cumprimento desta Política de Privacidade e Termo de Condições de Uso do SALES JOURNEY.<br /><br />

VI. Alteração da Política de Privacidade<br /><br />

O SALES JOURNEY reserva-se o direito de ocasionalmente alterar esta Política de Privacidade. O SALES JOURNEY informará ao usuário sobre a alteração por meio de publicação da nova versão na Plataforma SALES JOURNEY ou por outro meio razoável selecionado pelo SALES JOURNEY, antes de sua respectiva vigência. O uso da Plataforma SALES JOURNEY e/ou seus Serviços após a vigência da nova versão configurará a aceitação por parte do usuário das alterações havidas e da versão consolidada e atualizada da Política de Privacidade.<br /><br />

VII.Contato<br /><br />

Caso necessite de qualquer auxílio para exercer seus direitos, o usuário pode entrar em contato com o SALES JOURNEY, conforme orientações nesta Política.<br /><br />

Parte destes direitos poderão ser exercidos diretamente pelo usuário, a partir da gestão de informações sobre sua própria Conta na Plataforma. As demais alterações dependerão do envio de solicitação para posterior avaliação e adoção de demais providências pelo SALES JOURNEY.<br /><br />

As eventuais comunicações, consultas e requerimentos de qualquer natureza relacionadas ao tratamento de dados pessoais e aos termos da presente política de privacidade podem ser encaminhadas para o e-mail.<br /><br />

O usuário fica ciente de que a exclusão das informações essenciais para gestão de sua conta junto ao SALES JOURNEY implicará no término de seu cadastro, com consequente cancelamento dos serviços estão prestados.<br /><br />

VIII. Disposições Gerais<br /><br />

Caso alguma desta Política de Privacidade seja considerada ilegal ou ilegítima por autoridade da localidade em que resida ou da sua conexão a Internet, as demais condições permanecerão em pleno vigor e efeito.<br /><br />

A presente Política de Privacidade será regida e interpretada segundo a legislação brasileira, especialmente a Lei nº 13.709/2018, independentemente das Leis de outros estados ou Países, sendo eleito o Foro da Comarca de Domicílio do usuário para dirimir qualquer litígio ou controvérsia envolvendo o presente documento, salvo ressalva específica de competência pessoal, territorial ou funcional pela legislação aplicável.<br /><br />

São Paulo, 8 de abril de 2021<br /><br />

SALES JOURNEY
</p>


      </section>


      </div>
    </main>
  );
}

export default Politica;
