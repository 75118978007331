import Agendar_form from "../components/Agendar_form";
import Clients_logos from "../components/Clients_logos";
import Beneficios_cards from "../components/Beneficios_cards";
import Diferenciais_cards from "../components/Diferenciais_cards";
import Planos_cards from "../components/Planos_cards";
import Depoiments_slide from "../components/Depoiments_slide";

import plataforma_img1 from "../assets/images/plataforma_img1.png";
import plataforma_img2 from "../assets/images/plataforma_img2.png";
import arquitetura_img1 from "../assets/images/arquitetura_img1.png";
import arquitetura_img2 from "../assets/images/arquitetura_img2.png";

function Home() {
  return (
    <main className="main--home">
      <div className="content">


      <section className="justify-content-center" id="section01">
        <h2 className="h2" data-aos="zoom-out-down" data-aos-duration="800"><b>Mais que um CRM.</b><br/>Um novo mundo de controle e eficiência.</h2>
        <p className="fontcolor-opacity">Vá além da agilidade e organização com sua equipe de vendas.</p>

        <p>Comece agora mesmo uma verdadeira jornada de sucesso!</p>

        <Agendar_form />

        <div className="home-plataforma_img">
          <img src={plataforma_img1} className="plataforma_img1" data-aos="fade-right" data-aos-duration="1000" />
          <img src={plataforma_img2} className="plataforma_img2" data-aos="fade-left" data-aos-duration="400" data-aos-delay="200"/>
        </div>

      </section>



      <section className="justify-content-center bg-secundary" id="section02">
        <h2 className="h2 fontcolor-white">Jornada de vendas eficaz é aquela em que todos ficam satisfeitos: o seu cliente pela solução. Você, pela rentabilidade.</h2>
        
        <Beneficios_cards />

      </section>
      

      <section className="justify-content-center" id="section03">
        <h2 className="h2 fontcolor-primary">Empresas que já aceleram suas jornadas</h2>

        <Clients_logos />

      </section>



      <section className="justify-content-center bg-primary" id="section04">
        <h2 className="h2 fontcolor-white">Sabe por que somos mais que um CRM? Veja nossos diferenciais.</h2>

        <Diferenciais_cards />
        
      </section>



      <section className="justify-content-center" id="section05">
        <h2 className="h2 fontcolor-primary">Arquitetura de integração.</h2>
        <p className="fontcolor-opacity">Com uma robusta camada de APIs, o Sales Journey pode ser integrado facilmente.</p>

        <div className="home-arquitetura_img">
          <img src={arquitetura_img1} className="arquitetura_img1" data-aos="zoom-in-down" data-aos-duration="1000" data-aos-delay="200"/>
          <img src={arquitetura_img2} className="arquitetura_img2" data-aos="zoom-out-down" data-aos-delay="200"/>
        </div>

      </section>



      <section className="justify-content-center bg-primary" id="section06">

        <Planos_cards />
        
      </section>



      <section className="justify-content-center" id="section07">

        <Depoiments_slide />
        
      </section>



      </div>
    </main>
  );
}

export default Home;
