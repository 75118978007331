import Depoiments_slide from "../components/Depoiments_slide";
import Planos_table from "../components/Planos_table";

import Planos_cards from "../components/Planos_cards";
import Agendar_form from "../components/Agendar_form";

import planos_img1 from "../assets/images/planos1.png";
import planos_img2 from "../assets/images/planos2.png";
import planos_img3 from "../assets/images/planos3.png";
import planos_img4 from "../assets/images/planos4.png";

function Planos() {
  return (
    <main className="main--Planos">
      <div className="content">


      <section className="justify-content-center" id="section14">
        <h2 className="h2 fontcolor-primary"  data-aos="zoom-out-down" data-aos-duration="800"><b>Seus objetivos, nossa jornada de sucesso!</b></h2>
        <p className="fontcolor-opacity">Somos a melhor ferramenta estratégica para sua equipe de vendas alcançar os objetivos.</p>

        <div className="page-planos_img">
          <img src={planos_img1} className="planos_img1" data-aos="zoom-in" data-aos-duration="200" data-aos-delay="0" />
          <img src={planos_img2} className="planos_img2" data-aos="flip-right" data-aos-duration="800" data-aos-delay="200"/>
          <img src={planos_img3} className="planos_img3" data-aos="flip-right" data-aos-duration="800" data-aos-delay="400"/>
          <img src={planos_img4} className="planos_img4" data-aos="flip-right" data-aos-duration="800" data-aos-delay="600"/>
        </div>

      </section>



      <section className="justify-content-center bg-primary" id="section15">

        <Planos_cards />
        
      </section>



      <section className="justify-content-center bg-primary" id="section16">

        <Planos_table />

      </section>



      <section className="justify-content-center" id="section17">

        <Depoiments_slide />
        
      </section>



      <section className="justify-content-center" id="section18">

        <h2 className="h2 fontcolor-white">Ficou com alguma dúvida para decidir qual é a melhor solução para seu time de vendas?</h2>

        <Agendar_form />
        
      </section>


      </div>
    </main>
  );
}

export default Planos;
