import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import logo from "../assets/images/logo-dark.png";
import '../App.css';

import instagram_icon from "../assets/images/icons/instagram.png";
import youtube_icon from "../assets/images/icons/youtube.png";
import linkedin_icon from "../assets/images/icons/linkedin.png";
import language_icon from "../assets/images/icons/language.png";

const Navheader = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <nav
      className="navbar is-primary"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isOpen && "is-active"}`}>
          <div className="navbar-start">

            <div className="navheader-logo">

              <NavLink className="navbar-item navbar-logo" activeClassName="is-active" to="/">
              <img src={logo} className="logo" alt="Sales Journey" />
              </NavLink>

            </div>

            <div className="navheader-pages">

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/produto"
              >
                Produto
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/planos"
              >
                Planos
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                Blog
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/contato"
              >
                Contato
              </NavLink>

            </div>

            <div className="navheader-sociais">

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                <img src={instagram_icon} />
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                <img src={youtube_icon} />
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                <img src={linkedin_icon} />
              </NavLink>

            </div>

            <div className="navheader-languages">

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                <img src={language_icon} />
                <ul class="dropdown-menu">
                  <li><a class="dropdown-item" href="#">Português</a></li>
                  <li><a class="dropdown-item" href="#">Inglês</a></li>
                </ul>

              </NavLink>

            </div>

            <div className="navheader-btns">

              <NavLink
                className="navbar-item btn btn-lg btn-primary"
                activeClassName="is-active"
                to="/gratis"
              >
                Comece agora é grátis
              </NavLink>

              <NavLink
                className="navbar-item btn btn-lg btn-secundary"
                activeClassName="is-active"
                to="#"
              >
                Login
              </NavLink>

            </div>

          </div>

        </div>
      </div>
    </nav>
  );
};

export default Navheader;