import Trabalheconosco_form from "../components/Trabalheconosco_form";

function Trabalheconosco() {
  return (
    <main className="main--Trabalheconosco">
      <div className="content">


      <section className="justify-content-center page-form" id="section22">
        <h2 className="h2 fontcolor-primary" data-aos="zoom-out-down" data-aos-duration="800"><b>Falta pouco ...</b></h2>
        <p className="fontcolor-opacity">Preencha o formulário com suas informações que em poucos minutos você  estará utilizando o Sales Journey. <span className="fontcolor-primary">É grátis!</span></p>

        <Trabalheconosco_form />

      </section>


      </div>
    </main>
  );
}

export default Trabalheconosco;
