import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';

import client1 from "../assets/images/client/01.png";
import client2 from "../assets/images/client/02.png";
import client3 from "../assets/images/client/03.png";
import client4 from "../assets/images/client/04.png";
import client5 from "../assets/images/client/05.png";
import client6 from "../assets/images/client/06.png";


function Clients_logos() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  return (
    <div className="clients_logos">
      <div style={{ padding: `0 ${chevronWidth}px` }} className="clients_content">
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={3}
          gutter={20}
          leftChevron={<button className="slide_prev">{'<'}</button>}
          rightChevron={<button className="slide_next">{'>'}</button>}
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div style={{ height: 80 }} className="clients_item"><img src={client1} /></div>
          <div style={{ height: 80 }} className="clients_item"><img src={client2} /></div>
          <div style={{ height: 80 }} className="clients_item"><img src={client3} /></div>
          <div style={{ height: 80 }} className="clients_item"><img src={client4} /></div>
          <div style={{ height: 80 }} className="clients_item"><img src={client5} /></div>
          <div style={{ height: 80 }} className="clients_item"><img src={client6} /></div>
        </ItemsCarousel>
      </div>




    </div>
  );
}

export default Clients_logos;
