import React, { useState } from 'react';
import ItemsCarousel from 'react-items-carousel';

import client1 from "../assets/images/client/01.png";
import client2 from "../assets/images/client/02.png";


function Depoiments_slide() {
  const [activeItemIndex, setActiveItemIndex] = useState(0);
  const chevronWidth = 40;
  return (
    <div className="depoiments_slide">
      <h2 className="h2 fontcolor-primary">O que nossos clientes falam.</h2>

      <div style={{ padding: `0 ${chevronWidth}px` }} className="depoiments_content">
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={1}
          gutter={20}
          leftChevron={<button className="slide_prev">{'<'}</button>}
          rightChevron={<button className="slide_next">{'>'}</button>}
          outsideChevron
          chevronWidth={chevronWidth}
        >
          <div style={{ height: 140 }} className="depoiments_item"><img src={client1} /><p>“Através do Sales Journey, nosso consultor integra automaticamente os pedidos de vendas com nosso ERP (SAP), agilizando a jornada de venda e envio do produto aos nossos clientes.”</p><h6>Thiago Sano, Diretor de Tech & Digital na MedBeauty</h6></div>
          <div style={{ height: 140 }} className="depoiments_item"><img src={client2} /><p>“Através do Sales Journey, nosso consultor integra automaticamente os pedidos de vendas com nosso ERP (SAP), agilizando a jornada de venda e envio do produto aos nossos clientes.”</p><h6>Thiago Sano, Diretor de Tech & Digital na MedBeauty</h6></div>
        </ItemsCarousel>
      </div>




    </div>
  );
}

export default Depoiments_slide;
