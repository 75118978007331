import React, { useEffect } from "react";
import "./assets/css/bootstrap.min.css";
import "./App.css";
import "./App-mobile.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css";

//import Navheader from "./core/menus/Navheader";

import Header from "./core/Header";
import Footer from "./core/Footer";

import Home from "./pages/Home";
import Produto from "./pages/Produto";
import Planos from "./pages/Planos";
import Contato from "./pages/Contato";
import Trabalheconosco from "./pages/Trabalheconosco";
import Gratis from "./pages/Gratis";
import Termos from "./pages/Termos";
import Politica from "./pages/Politica";

function App() {

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <BrowserRouter>
      <Header />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/produto" element={<Produto />} />
          <Route path="/planos" element={<Planos />} />
          <Route path="/contato" element={<Contato />} />
          <Route path="/trabalheconosco" element={<Trabalheconosco />} />
          <Route path="/gratis" element={<Gratis />} />
          <Route path="/termos" element={<Termos />} />
          <Route path="/politica" element={<Politica />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;