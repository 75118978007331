import React, { useState } from "react";
import { NavLink } from "react-router-dom";

const Navfooter = () => {
  const [isOpen, setOpen] = useState(false);
  return (
    <nav
      className="navbar is-footer"
      role="navigation"
      aria-label="main navigation"
    >
      <div className="container">
        <div className="navbar-brand">
          <a
            role="button"
            className={`navbar-burger burger ${isOpen && "is-active"}`}
            aria-label="menu"
            aria-expanded="false"
            onClick={() => setOpen(!isOpen)}
          >
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>

        <div className={`navbar-menu ${isOpen && "is-active"}`}>
          <div className="navbar-start">

            <div className="navfooter-pages">

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/produto"
              >
                Produto
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/planos"
              >
                Planos
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                Blog
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                Help Center
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/contato"
              >
                Contato
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="#"
              >
                Login
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/gratis"
              >
                Experimente grátis
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/trabalheconosco"
              >
                Trabalhe conosco
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/termos"
              >
                Termos de uso
              </NavLink>

              <NavLink
                className="navbar-item"
                activeClassName="is-active"
                to="/politica"
              >
                Políticas de privacidade
              </NavLink>

            </div>

          </div>

        </div>
      </div>
    </nav>
  );
};

export default Navfooter;