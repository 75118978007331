import Clients_logos from "../components/Clients_logos";
import Beneficios_cards from "../components/Beneficios_cards";
import Diferenciais_cards from "../components/Diferenciais_cards";

import clevels1 from "../assets/images/clevels/01.png";
import clevels2 from "../assets/images/clevels/02.png";
import clevels3 from "../assets/images/clevels/03.png";

function Produto() {
  return (
    <main className="main--produto">
    <div className="content">


      <section className="justify-content-center" id="section08">
        <h2 className="h2 fontcolor-primary" data-aos="zoom-out-down" data-aos-duration="800"><b>Por que somos mais que um CRM?</b></h2>
        <p className="fontcolor-opacity">O Sales Journey é uma Plataforma SaaS, lead-to-cash (oportunidade a fatura), mobile-first, que traz os benefícios da mobilidade, fluxos de trabalho de backoffice e uma camada aberta de APIs, permitindo a integração com qualquer solução, desde um ERP a uma solução de marketing.</p>

        <div className="video-produto" data-aos="zoom-in" data-aos-duration="200" data-aos-delay="0"><video></video></div>

      </section>



      <section className="justify-content-center" id="section09">
        <h4 className="h4 fontcolor-primary">Nossa tecnologia disponibiliza uma experiência inovadora para o seu time de vendas e clientes, oferecendo todas as informações necessárias para uma venda qualificada.</h4>

        <Diferenciais_cards />
        
      </section>



      <section className="justify-content-center bg-secundary" id="section10">
        <h2 className="h2 fontcolor-white">Nossos benefícios</h2>
        
        <Beneficios_cards />

      </section>



      <section className="justify-content-center bg-primary" id="section11">
        <h2 className="h2 fontcolor-secundary">Nossa cultura</h2>
        <p className="fontcolor-white">Não somos apenas mais uma plataforma, mas um time que acredita que ao fazer diferente se chega mais longe. Não fazemos diferente apenas pela novidade, e sim pela mudança positiva que geramos para nossos clientes. <span className="fontcolor-secundary">E o que nos motíva é a crença de que podemos chegar mais longe juntos.</span></p>


        <div className="row cards-beneficios">

          <div className="col-sm-4" data-aos="zoom-in-right" data-aos-delay="100">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Comunicação ativa</h5>
                <p className="card-text">A comunicação é o que mantém uma empresa alinhada e produtiva. Agilidade no entendimento e produção são fatores cruciais para nossos clientes.</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4" data-aos="zoom-in-right" data-aos-delay="200">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Transparência</h5>
                <p className="card-text">Saber trabalhar com feedbacks, ser abertos a discusões e novas ideias é uma parte importe do nosso processo.</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4" data-aos="zoom-in-right" data-aos-delay="300">
            <div className="card">
              <div className="card-body">
                <h5 className="card-title">Cultura ágil</h5>
                <p className="card-text">Agilidade é mossa principal ferramenta para agregar valor ao dia-a-dia dos nossos clientes.</p>
              </div>
            </div>
          </div>

        </div>

        
      </section>



      <section className="justify-content-center" id="section12">
        <h2 className="h2 fontcolor-primary">Empresas que já aceleram suas jornadas</h2>

        <Clients_logos />

      </section>



      <section className="justify-content-center bg-secundary" id="section13">
        <h2 className="h2 fontcolor-white">C-Levels Sales Journey - mudar este texto</h2>

        <div className="row cards-clevels">

          <div className="col-sm-4" data-aos="flip-up" data-aos-duration="500" data-aos-delay="100">
            <div className="card">
              <div className="card-body">
                <img src={clevels1} />
                <h5 className="card-title">Tobias Junior</h5>
                <p className="card-text">(CPTO) - Chief Product and Technology Officer</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4" data-aos="flip-up" data-aos-duration="500" data-aos-delay="200">
            <div className="card">
              <div className="card-body">
                <img src={clevels2} />
                <h5 className="card-title">Ana Senko</h5>
                <p className="card-text">(COO) - Chief Operating Officer</p>
              </div>
            </div>
          </div>

          <div className="col-sm-4" data-aos="flip-up" data-aos-duration="500" data-aos-delay="300">
            <div className="card">
              <div className="card-body">
                <img src={clevels3} />
                <h5 className="card-title">Carlos Tofanello</h5>
                <p className="card-text">(CFO) - Chief Financial Officer</p>
              </div>
            </div>
          </div>

        </div>

        
      </section>



      </div>
    </main>
  );
}

export default Produto;
