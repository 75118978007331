function Termos() {
  return (
    <main className="main--termos">
      <div className="content">


      <section className="justify-content-center page-text" id="section24">
        <h2 className="h2 fontcolor-primary" data-aos="zoom-out-down" data-aos-duration="800"><b>Termos de serviço.</b></h2>
        <p className="fontcolor-opacity">TERMO DE ACEITE E CONDIÇÕES DE USO DO SALES JOURNEY</p>


<p><b>Contrato de Licença de Uso de Usuário Final<br /><br />

POR FAVOR, LEIA CUIDADOSAMENTE ESTE CONTRATO ANTES DE UTILIZAR O PRODUTO SALES JOURNEY E TODOS OS SERVIÇOS NELE OFERECIDOS PELA EMPRESA TANGOTECH. AO UTILIZAR A SOLUÇÃO SALES JOURNEY, VOCÊ CONCORDA E ACEITA OS TERMOS E CONDIÇÕES DESTE CONTRATO. CASO NÃO CONCORDE, NÃO USE A PLATAFORMA E OS SERVIÇOS DA CONTRATADA.</b><br /><br />

Este Contrato de Licença de Usuário Final é um acordo legal entre o usuário CLIENTE e a TANGO TECH CONSULTORIA EM INFORMATICA LTDA., sociedade empresária limitada com sede na cidade de São Paulo/SP, na Rua Gomes de Carvalho, 1666, Conj. 21, Vila Olímpia, CEP 04547-006, inscrita no CNPJ nº 40.613.173/0001-08 - CONTRATADA, detentora de todos os direitos de propriedade intelectual e titularidade do produto SALES JOURNEY, cuja licença de uso é cedida ao CLIENTE, por este instrumento denominado “Termo de aceite e Condições de Uso do SALES JOURNEY”, e na melhor forma do Direito, é adiante regulado o relacionamento entre a CONTRATADA e o CLIENTE, o qual declara ter lido, compreendido e aceito todos os termos e condições deste instrumento, que constitui a totalidade do acordo entre as Partes, acrescida a esse, tão somente, a proposta a que se baseou a negociação entre as partes e eventuais aditivos.<br /><br />

1. DEFINIÇÕES<br /><br />

1.1. Além dos termos definidos no corpo deste Contrato, os seguintes termos, terão a definição que lhes é atribuída abaixo:<br /><br />

SETUP: Etapa que permite a configuração e acesso aos Serviços do SALES JOURNEY e que podem ser descarregados pelo Usuário para instalação nos dispositivos ou aparelho móvel, através da plataforma virtual ou outra forma indicada pela CONTRATANTE;<br /><br />

PLATAFORMA: É a plataforma virtual da CONTRATADA, disponível no site: salesjourney.io, ou através da instalação pelos operadores da CONTRATADA junto aos equipamentos do CLIENTE, ferramentas e funcionalidades que servirão para a gestão de oportunidades, pedidos de vendas, pricing, estoque, margem real, limite de crédito, entre outras, oferecidas pela plataforma de propriedade e titularidade da CONTRATADA o qual poderá integrar funcionalidades com particularidades de acordo com proposta;<br /><br />

1.1.3. CONTA: é o ambiente acessível por meio dos Dados de Acesso onde cada USUÁRIO poderá fazer uso da Plataforma, gerindo suas atividades profissionais e o seu Conteúdo;<br /><br />

1.1.4. CONTEÚDO: são os dados operacionais próprios das atividades profissionais do CLIENTE, inseridos, gerados, recebidos ou organizados na Conta do Cliente, em decorrência da fruição dos Serviços, podendo incluir Dados Financeiros, Dados de Usuários, Dados de Cadastro, informações em gerais pertencentes a “cadeia” de atividades que a plataforma organizará com seus préstimos;<br /><br />

1.1.5. CLIENTE: é a pessoa física ou jurídica que adere ao presente instrumento para utilização do SALLES JOURNEY, em caráter de experiência ou definitivo;<br /><br />

1.1.6. DADOS DE ACESSO: são as informações de login e senha que permitem o acesso a uma Conta de Usuário, classificadas e organizas pelas descrições de licença;<br /><br />

1.1.7. DADOS DE CADASTRO: são as informações do CLIENTE, inseridas na PLATAFORMA pelo CLIENTE, que permitam o acesso, a geração dos Dados de Acesso e a contratação dos Serviços, exceto pelos Dados Financeiros;<br /><br />

1.1.8. DADOS FINANCEIROS: são as informações financeiras do CLIENTE e seus USUÁRIOS utilizados para viabilizar o pagamento das retribuições pelos Serviços contratados;<br /><br />

1.1.9. DADOS DOS USUÁRIOS: Os Dados dos Usuários incluem sem se limitar: nome do Usuário, endereço, dados cadastrais, números de telefones, fax, e-mails, número de documentos e os registros relacionados ao uso da Plataforma;<br /><br />

1.1.10. INFORMAÇÕES: é o conjunto de todos os dados inseridos, gerados, recebidos ou organizados na Conta do Cliente, em decorrência da fruição dos Serviços, inclusive o Conteúdo, os Dados de Cadastro, Dados Financeiros, Dados dos Usuários, dados de login e senha de acesso a processos eventualmente em sigilo, registros e aceites realizados no SALES JOURNEY, entre outros, os quais em conjunto ou em parte constituem o CONTEÚDO do CLIENTE;<br /><br />

1.1.11. LICENCIAMENTO: é a relação jurídica existente entre CLIENTE e CONTRATADA, em razão da contratação dos Serviços da Plataforma pelo CLIENTE;<br /><br />

1.1.12. PERÍODO DE EXPERIÊNCIA: caso eventualmente tenha sido objeto de contratação e segundo proposta previamente ajustada entre as partes, o que consiste em um período de gratuidade dos Serviços, disponibilizado a critério e por liberalidade da CONTRATADA, cuja duração será determinada pela política então vigente da CONTRATADA;<br /><br />

1.1.13. PLANO: refere-se a cada conjunto de Serviços e funcionalidades da Plataforma oferecida, considerando o preço ajustado, forma de pagamento para fruição pelo CLIENTE durante prazo determinado, divulgado no SALES JOURNEY, conforme vigente à época de sua contratação e de acordo com proposta que a essa integra.<br /><br />

1.1.14. POLÍTICA DE PRIVACIDADE: é o conjunto de diretrizes que regem a utilização das Informações, sobretudo e em especial as que tratam a LGPD que a de dirimir o tema em sua amplitude;<br /><br />

1.1.15. SERVIÇOS: são as funcionalidades oferecidas pelo SALES JOURNEY para automação, dashboard gerencial, workflow, pricing dinâmica, estoque, margem real, limite de crédito simulador de vendas entre outras (todas funcionalidades melhores detalhas e esclarecidas junto a respectiva proposta que integra o presente), que são oferecidas pela plataforma de propriedade e titularidade da CONTRATADA o qual eventualmente poderá integrar funcionalidades oferecidas por Softwares de Terceiros;<br /><br />

1.1.16. SERVIÇOS ADICIONAIS: diante da eventualidade, são os serviços de migração de dados, treinamento e outros serviços de prestação não continuada, oferecidos pela Plataforma, para contratação avulsa pelo CLIENTE, em condições de preço, pagamento e prazo então vigentes, conforme informado em proposta;<br /><br />

1.1.17. SERVIÇOS CONTINUADOS: são os Serviços de uma determinada licença contratada pelo CLIENTE;<br /><br />

1.1.18. SOFTWARE DE TERCEIRO: são os softwares de propriedade de terceiros que acabam por serem integrados à plataforma SALES JOURNEY, cuja integração servem tanto para o envio quanto para o recebimento de informações, entre outras eventuais necessidades a serem supridas para a perfeita funcionalidade da plataforma;<br /><br />

1.1.19. USUÁRIO: é toda a pessoa física cadastrada pelo CLIENTE para operar a Plataforma, a qual uma vez cadastrada, submete-se automaticamente ao presente Termo de Uso e à Política de Privacidade.<br /><br />

2. OBJETO<br /><br />

2.1. Este instrumento estabelece os termos e condições aplicáveis à contratação pelo CLIENTE da licença revogável, não exclusiva e intransferível, sobre o direito de uso dos serviços gerados pela plataforma do SALES JOURNEY.<br /><br />

2.2. São anexos e partes integrantes deste Termo, a Política de Privacidade produzida e elaborada pela CONTRATADA, e as informações específicas informadas pelo CLIENTE quanto ao número de Usuários, quantidade e características dos Serviços contratados considerando a pessoalidade tratada pela proposta que inicialmente aproximou as partes à época da contratação.<br /><br />

3. DOS SERVIÇOS<br /><br />

3.1. A Plataforma coloca os seguintes Serviços disponíveis para a contratação pelo CLIENTE:<br /><br />

3.1.1. geração de relatórios e dashboards nativos que apresentam visões analíticas e sintéticas, fácil análise para uma rápida tomada de decisão;<br /><br /> Processos com regras internas de autorização, trazendo governança e integridade aos processos;<br /><br /> Formação do preço através de camadas, permitindo esta composição da pricing através de regras avançadas;<br /><br /> Controle de estoque interno com atualização para consultar em real-time;<br /><br /> Apresentação do cálculo apurado dos resultados de vendas;<br /><br /> Opção de regra para limite de crédito conforme o compromisso do cliente;<br /><br /> Ferramenta de simulação de vendas baseado em diferentes variáveis, como pricing, vendedor e Item, sem prejuízo, contudo, do previsto na Cláusula 3.1.3.<br /><br />

3.1.2 Sugere o prazo de 60 (sessenta) dias para que a plataforma esteja em sua perfeita funcionalidade, dependendo, contudo, das informações e dados prestados, bem como, treinamento completo que serão oferecidos com a finalidade de capacitar os Usuários para o melhor aproveitamento da Plataforma pelos mesmos. Suas condições de contratação são aquelas previstas na Plataforma, no momento da contratação.<br /><br />

3.1.3. A CONTRATADA poderá a seu exclusivo critério adicionar novos Serviços, modificá-los, alterar suas condições de oferta, bem como deixar de oferecê-los, sem necessidade de aviso prévio e sem qualquer ônus à CONTRATADA, na medida máxima permitida pela lei.<br /><br />

4. CONTRATAÇÃO E CONFIGURAÇÃO DOS PLANOS<br /><br />

4.1. a Contratação seguirá a proposta que antecede a adesão a essa disponíveis a título oneroso, salvo se convencionado entre as partes período de experiência, pelo prazo de vigência estipulado em proposta.<br /><br />

4.2. Na contratação do Período de Experiência ou dos Serviços Continuados, o CLIENTE elegerá os Serviços que pretende contratar, bem como o dimensionamento de sua demanda, informando na própria Plataforma o número de Usuários, e qual a licença que pretende usufruir. Com base nessas informações, a Plataforma indicará o Plano a ser contratado, bem como o seu correspondente valor.<br /><br />

4.2.1. O prazo de vigência da contratação será de 1 ano, admitida a renovação automática caso não haja manifestação das partes.<br /><br />

4.3. O CLIENTE poderá, a qualquer tempo, durante a vigência de um Plano de Serviços Continuados, contratar um número adicional de acessos para Usuários,<br /><br />

4.3.1. Os Acréscimos em um Plano resultarão na geração de um valor adicional para pagamento pelo CLIENTE à CONTRATADA, o qual será calculado proporcionalmente aos considerando o período remanescente do Plano em vigor, conforme os preços então divulgados na Plataforma.<br /><br />

4.4. O CLIENTE poderá ainda contratar a qualquer tempo os Serviços Adicionais, conforme estejam disponíveis na Plataforma e pelo prazo de duração e preço informados no momento da contratação conforme divulgado na Plataforma.<br /><br />

4.5. A CONTRATADA poderá a seu exclusivo critério cancelar ou modificar as condições de oferta de qualquer dos Serviços, sem aviso prévio e sem qualquer ônus, na medida máxima permitida pela lei.<br /><br />

5. USUÁRIOS<br /><br />

5.1. No que se referem aos Usuários O CLIENTE deverá inserir corretamente os dados solicitados pela Plataforma para o cadastramento de Usuários até o limite contratado, bem como gerar as senhas de acesso de cada Usuário.<br /><br />

5.1.1. Todos os Usuários cadastrados pelo CLIENTE terão acesso aos mesmos Serviços contratados pelo CLIENTE e a todas as informações providas pelos Serviços, salvo exceções previstas eventualmente na proposta.<br /><br />

5.1.2. Cabe exclusivamente ao CLIENTE a responsabilidade pela limitação dos poderes administrativos de cada Usuário no que dizer respeito às possibilidades técnicas oferecidas no ambiente de configuração de Usuários, inclusive mas sem se limitar à aplicação de restrições a Usuários específicos para (a) alteração e cancelamento da licença contratada;<br /><br /> (b) contratação de Serviços Adicionais;<br /><br /> (c) acesso a módulos financeiros;<br /><br /> e (d) acesso a processos específicos, seguindo a especificidade da licença contratada.

5.1.3 É de responsabilidade exclusiva do CONTRATANTE os atos praticados por seus USUÁRIOS, comprometendo-se também a dar ciência a esses de que os Dados de Acesso às Contas de Usuários são pessoais e intransferíveis.<br /><br />

6. VIGÊNCIA<br /><br />

6.1. A partir da data do aceite ao presente Termo na própria Plataforma ou eventualmente dado o aceite presencial, se dará início a vigência do presente Termo.<br /><br />

6.2. Exceto pelas obrigações que, por determinação legal ou dispositivo contratual, devam sobreviver a este prazo, a duração do prazo de vigência deste Termo corresponderá a:<br /><br />

a) 1 (um ano), no caso de Planos contratados com duração anual;<br /><br />

b) 2 meses no caso de contratação de Plano em experiência;<br /><br />

6.3. Os Acréscimos contratados no decorrer de um Plano em vigor, na forma da Cláusula 4.4, estarão disponíveis para o CLIENTE pelo período remanescente do Plano então vigente.<br /><br />

6.4. As licenças serão automaticamente renovadas ao final do prazo de duração do Plano, a menos que o CLIENTE manifeste seu interesse em cancelar o seu Plano antes do final do período contratado. O cancelamento entrará em vigor no dia seguinte ao último dia do prazo de duração do Plano em vigor.<br /><br />

6.5. Sem prejuízo de outros dispositivos deste Contrato, no décimo dia após o término do prazo de vigência, sem que tenha havido renovação do Plano, o acesso do CLIENTE à Plataforma será automaticamente interrompido, independentemente de qualquer aviso prévio.<br /><br />

6.6. Transcorridos 60 (sessenta) dias a contar da data de término da vigência deste Termo, as Informações serão apagadas do banco de dados da CONTRATADA, exceto por aquelas que mandatoriamente devam ficar em poder da CONTRATADA.<br /><br />

7. REMUNERAÇÃO<br /><br />

7.1. Em retribuição ao Licenciamento ora contratado, o CLIENTE deverá remunerar a CONTRATADA de acordo com os valores vigentes e divulgados na PROPOSTA à época da contratação.<br /><br />

7.2. Os pagamentos serão feitos sempre antecipadamente, faturado pela CONTRATADA com o envio dos boletos para pagamento pelo CLIENTE, ou atendida outra forma exclusiva para pagamento, que eventualmente tenha sido tratada na proposta.<br /><br />

7.3. A contratação de Serviços Adicionais se dará pelos valores vigentes na data de sua contratação, conforme divulgado pelo SALES JOURNEY à época, não se alterando, contudo, os valores dos Serviços Adicionais tampouco dos Serviços Continuados contratados anteriormente.<br /><br />

7.4. A retribuição dos Serviços Continuados será reajustada anualmente ou na menor periodicidade permitida por lei, de maneira automática, com base na variação positiva do IGP-M (ou o índice que vier a sucedê-lo). Para os fins desta Cláusula, considera-se como data base da periodicidade anual para reajustes, a data de início de vigência deste Termo resultante da primeira contratação de um Plano pelo CLIENTE, na hipótese de sucessivas renovações de vigência do mesmo Plano.<br /><br />

7.5.1. Caberá ao CLIENTE, caso discorde do reajuste, cancelar o Plano antes do vencimento da retribuição que reflete o referido reajuste. A continuidade da utilização dos Serviços pelo CLIENTE após o conhecimento do preço reajustado será considerada como aceite à renovação do Plano pelo novo preço após o reajuste ter entrado em vigor.<br /><br />

7.6. O não pagamento da retribuição no prazo estipulado acarretará na incidência de multa de 2% (dois por cento), acrescida dos juros de mora de 1% (um por cento) ao mês, além da correção mensal prevista na cláusula 7.4. até que haja o efetivo pagamento.<br /><br />

7.7. O CLIENTE permanecerá responsável pelo pagamento na data de vencimento, ainda que haja atraso, por qualquer motivo, da entrega do boleto bancário ou cobrança de qualquer espécie até a data do pagamento, devendo contactar a CONTRATADA para se eximir da mora.<br /><br />

7.8. O atraso do pagamento não sanado pelo CLIENTE por prazo superior a 10 (dez) dias a contar da data de vencimento, acarretará na suspensão automática dos Serviços Continuados, Acréscimos ou Serviços Adicionais em atraso a partir do 11º (décimo primeiro) dia sem pagamento.<br /><br />

7.9. Após a comprovação do pagamento pelo CLIENTE à CONTRATADA, o restabelecimento dos Serviços suspensos ocorrerá em até 24 (vinte e quatro) horas.<br /><br />

7.10. Não haverá restituição de valores, modificação nas datas de vencimento ou redução de valor das retribuições subsequentes, como forma de compensar o período de suspensão dos Serviços por inadimplemento.<br /><br />

7.11. Caso o inadimplemento seja superior a 20 (vinte) dias a contar da data do vencimento da retribuição devida, considerar-se-á o presente instrumento rescindido, sem prejuízo do direito da CONTRATADA em tomar as medidas que entenda necessárias para o recebimento do pagamento das parcelas em atraso.<br /><br />

7.12. A CONTRATADA manterá sempre disponível em suas plataformas o preço de cada Serviço.<br /><br />

7.12.1. Os descontos promocionais concedidos para a contratação de determinados Planos (a) não são cumulativos, (b) são exclusivos do Plano a que se referem, e (c) tem prazo de validade, conforme informado pela CONTRATADA ao CLIENTE quando da oferta do desconto, o que sempre seguirá a proposta que antecedeu a contratação.<br /><br />

7.12.2. Eventual cancelamento antecipado do Plano ou redução dos Serviços e funcionalidades do Plano antes do final do prazo de vigência contratado pelo CLIENTE não implicará em restituição de valores pagos antecipadamente ou redução dos valores a pagarem razão da rescisão antecipada ou da redução dos Serviços e funcionalidades do Plano.<br /><br />

7.12.3. O CLIENTE declara ciência de que eventuais descontos, ofertas gratuitas de funcionalidades e módulos podem ser oferecidos em caráter temporário pela CONTRATADA, podendo a CONTRATADA alterá-las, descontinuá-las ou passar a cobrá-las sem necessidade de anuência prévia, desde que informe previamente o final da gratuidade e o valor a ser cobrado. Caso o CLIENTE discorde da alteração, poderá cancelar a contratação da funcionalidade ou módulo objeto da modificação, sob pena de a continuidade de utilização do SALES JOURNEY pelo CLIENTE seja considerado o aceite do preço informado.<br /><br />

8. RESCISÃO<br /><br />

8.1. Nos Planos de vigência anual ou no de experiência, o CLIENTE poderá rescindir o presente Contrato, imotivadamente, mediante aviso prévio de 30 (trinta) dias dado à CONTRATADA, por meio de comunicação à CONTRATADA, envio de e-mail ao endereço: atendimento@salesjourney.io. ou outro meio qualquer que seja suficiente a dar ciência inequívoca.<br /><br />

8.2. A resilição ou rescisão deste Contrato conforme previsto nesta Cláusula 8 não afetará o direito da CONTRATADA tomar as medidas que entenda cabíveis para a cobrança de eventuais débitos pendentes do CLIENTE, em decorrência do presente Contrato.<br /><br />

9. OBRIGAÇÕES DO CLIENTE<br /><br />

9.1. O CLIENTE reconhece e concorda que os Serviços oferecidos pela Plataforma são fornecidos mediante acesso à rede mundial de computadores–internet, sendo de sua exclusiva responsabilidade a aquisição e manutenção de equipamentos, serviços de provedores demais atributos técnicos adequados a permitir seu acesso ao SALES JORNEY, assim como a responsabilidade pela falha de funcionamento de seus equipamentos do CLIENTE, ou quaisquer limitações ou impedimentos técnicos de seus equipamentos e de sua conexão à rede internet do CLIENTE e aos Serviços disponibilizados na Plataforma, bem como, eventuais integrações com terceiros necessárias ao desempenho da prestação de serviço.<br /><br />

9.2. O CLIENTE é responsável pela utilização da Plataforma unicamente para a finalidade de gestão de sua prática profissional inerente a sua atividade econômica, conforme designado neste Contrato.<br /><br />

9.3. O CLIENTE declara e garante que todos os Dados do Cliente, Dados Financeiros e dos Usuários inseridos na Plataforma são verdadeiros e precisos, sendo a única e exclusiva responsável pela veracidade, validade e precisão das informações por ele fornecidas, inclusive em relação à indicação de endereço de e-mail válido de sua titularidade, competindo-lhe, ainda, manter referido cadastro sempre atualizado.<br /><br />

9.4. O CLIENTE é o único responsável pela inserção de dados corretos, válidos e precisos requeridos pela Plataforma para a perfeita fruição dos Serviços a que a CONTRATADA se propõe, bem como, pela atualização de tais dados.<br /><br />

9.4.1. Para a plena fruição dos Serviços prestados pela CONTRATADA deverá o CLIENTE se ater as diretrizes traçadas pela CONTRATADA no uso da solução SALES JOURNEY.<br /><br />

9.4.2. O CLIENTE deverá informar à CONTRATADA imediatamente ao constatar qualquer irregularidade que afete o funcionamento regular dos Serviços, de modo a possibilitar que a CONTRATADA tome as providências necessárias para o restabelecimento dos Serviços no menor prazo possível.<br /><br />

9.7 É expressamente vedado ao CLIENTE: a) Utilizar a Plataforma para finalidade diversa daquela prevista neste Termo, em violação de normas aplicáveis no Brasil, de direitos de propriedade da CONTRATADA e/ou de terceiros ou dos bons costumes, incluindo, sem limitação, a violação de direitos intelectuais, autorais e de privacidade, ou a produção e divulgação de conteúdo ilegal, imoral, inapropriado ou ofensivo;<br /><br /> b) Sublicenciar, transferir, vender, dar em garantia, doar, copiar, reproduzir, alienar de qualquer forma, ceder, total ou parcialmente, a qualquer título, o direito de uso objeto deste Licenciamento, inclusive, mas sem se limitar a seus manuais ou quaisquer informações relativas ao mesmo;<br /><br /> c) Reproduzir, adaptar, modificar e/ou empregar, no todo ou em parte, para qualquer fim, a Plataforma ou seu conteúdo, sem a autorização expressa da CONTRATADA;<br /><br /> d) Publicar conteúdo inapropriado, ilegal ou imoral na Plataforma;<br /><br /> e) Burlar a segurança da Plataforma para práticas nocivas à CONTRATADA e a terceiros;<br /><br /> f) Realizar engenharia reversa;<br /><br /> g) Publicar ou transmitir qualquer arquivo que contenha programa contaminante ou destrutivo, ou que de outra forma possa interferir no bom funcionamento da Plataforma, tais como vírus, cavalos de troia ou qualquer outro.<br /><br />

9.8. A CONTRATADA poderá, ao seu exclusivo critério, a qualquer tempo, e sem a necessidade de comunicação prévia ao CLIENTE:<br /><br />

a) Encerrar, modificar ou suspender, total ou parcialmente, o acesso do CLIENTE plataforma, quando referido acesso ou cadastro estiverem em violação das condições estabelecidas neste Termo;<br /><br />

b) Excluir, total ou parcialmente, as Informações cadastradas pelo CLIENTE que não estejam em consonância com as disposições deste Termo;<br /><br />

c) Remover ou desabilitar o acesso a Conteúdo que esteja em desacordo com este Termo.<br /><br />

9.9. É de responsabilidade do CLIENTE efetuar e manter seguras as cópias de seu Conteúdo, utilizando as ferramentas disponíveis na Plataforma antes de se desligar da Plataforma. Em até 60 (sessenta) dias a contar da data final do prazo de vigência do Plano Contratado, o CLIENTE poderá solicitar a exportação e envio de seu Conteúdo contido no banco de dados da CONTRATADA. O Conteúdo estará disponível para exportação em formato eletrônico (cópia da base de dados), em formato.xls ou outro formato determinado pela CONTRATADA. Após esse prazo, esse Conteúdo será apagado permanentemente dos servidores da CONTRATADA.<br /><br />

9.10. O CLIENTE concorda em defender, indenizar e manter indene a CONTRATADA e suas afiliadas, diretores, empregados e agentes, de e contra quaisquer encargos, ações ou demandas, incluindo, mas não se limitando a honorários advocatícios e custos judiciais, resultantes: (i) da sua eventual utilização indevida do SALES JOURNEY, ou da sua violação das condições pactuadas neste Termo, por si ou por qualquer de seus USUÁRIOS.<br /><br />

10. DISPONIBILIDADE DOS SERVIÇOS<br /><br />

10.1. A Plataforma estará à disposição do CLIENTE diariamente e em tempo integral, podendo a CONTRATADA interromper sua disponibilidade nas seguintes hipóteses: <br /><br />

a) realização de manutenções preventivas pela CONTRATADA: as manutenções preventivas ocorrerão em data e horário comunicados ao CLIENTE com pelo menos 1(dia) de antecedência;<br /><br /> 
b) realização de manutenções emergenciais decorrentes de falhas de funcionamento dos Serviços não causadas por erros ou defeitos da integração com Terceiros.<br /><br />

10.1.1 As manutenções necessárias serão realizadas no menor tempo possível, tão logo sejam detectadas pela CONTRATADA ou comunicadas a ela pelo CLIENTE.<br /><br />

10.2. Além dos eventos previstos na Cláusula 10.1, a Plataforma e os Serviços poderão estar indisponíveis nos casos abaixo: <br /><br />

a) interrupção do fornecimento de energia elétrica;<br /><br /> 
b) falha da conexão à internet(“link”), fornecida pela empresa de telecomunicações encarregada da prestação dos serviços;<br /><br /> 
c) defeitos, mal funcionamento ou paradas para manutenção de Software ou qualquer outra forma de integração com Terceiros;<br /><br /> d) eventos fora do controle da CONTRATADA, inclusive mas sem se limitar (i) eventos causados por atos ou omissões do CLIENTE ou de terceiros;<br /><br /> eventos resultantes de falhas do equipamento de hardware, software ou outras tecnologias utilizadas pelo CLIENTE;<br /><br /> bem como quaisquer casos fortuitos e de força maior, assim definidos pelo Artigo 393, do Código Civil.<br /><br />

11. SUPORTE<br /><br />

11.1. A CONTRATADA se obriga a disponibilizar aos CLIENTES as novas versões, implementações e melhorias dos serviços e funcionalidades oferecidos no âmbito da Plataforma e da licença contratada, bem como, a prestar suporte técnico ao CLIENTE durante todo o prazo de duração dos Serviços, garantindo a disponibilidade do produto e suas funcionalidades.<br /><br />

11.2. O suporte técnico será prestado por profissionais capacitados, dentro do horário comercial compreendido entre 9h e 18h no fuso horário de Brasília, de segunda à sexta, por meio dos canais "E-mail" ou ferramenta para abertura de chamados sem qualquer custo adicional, prestando os esclarecimentos de quaisquer dúvidas, recebendo sugestões e críticas relativas aos Serviços.<br /><br />

11.3. As solicitações serão atendidas pelo suporte técnico da CONTRATADA sem discriminação e por ordem de chegada das solicitações.<br /><br />

11.4. A CONTRATADA não garante que todas as dúvidas poderão ser esclarecidas e todos os problemas resolvidos em um único atendimento.<br /><br />

12. CONFIDENCIALIDADE E SEGURANÇA DE DADOS<br /><br />

12.1. As Informações serão tratadas por sigilosas pela CONTRATADA, não sendo permitida a divulgação a terceiros estranhos à essa contratação, exceto nas hipóteses previstas neste Contrato.<br /><br />

12.2. Não são consideradas violações ao dever de sigilo e segurança a divulgação ou recebimento de informações que: a) estavam no domínio público na data da celebração do presente Contrato;<br /><br /> b) eram ou tornaram-se conhecidas pelo público em geral;<br /><br /> c) eram conhecidas pela CONTRATADA antes de ter sido recebida do CLIENTE;<br /><br /> d) forem reveladas em razão de qualquer ordem, decreto, despacho, decisão ou regra emitidos por qualquer órgão judicial, legislativo ou executivo;<br /><br /> e) sejam recebidas de terceiros e/ou tenham sido desenvolvidas independentemente pela CONTRATADA;<br /><br /> ou f) sejam reveladas em razão de exigência legal ou por ordem judicial, hipótese na qual a CONTRATADA comunicará o CLIENTE, previamente à divulgação.<br /><br />

12.3. O CLIENTE concorda que os registros das operações que correspondem à aceitação de determinadas opções na Plataforma serão mantidos nos bancos de dados da CONTRATADA, juntamente com a data e hora de manifestação do aceite pelo CLIENTE, podendo tal informação ser utilizada como prova da aceitação da opção pelo CLIENTE, independentemente de outra formalidade.<br /><br />

12.4. Em nenhuma hipótese, a CONTRATADA poderá ceder, transferir, alienar ou disponibilizar as Informações do CLIENTE.<br /><br />

12.5. O CLIENTE expressamente autoriza a CONTRATADA a utilizar as Informações para (a) envio de notificações, alertas e comunicados;<br /><br /> (b) promover o funcionamento das características dos Serviços disponíveis na Plataforma;<br /><br /> (c) aperfeiçoar as características e funcionalidades dos Serviços oferecidos pela Plataforma;<br /><br /> (d) gerar estatísticas genéricas para monitoramento de utilização da Plataforma;<br /><br /> (e) realizar pesquisas de marketing, planejamento de projetos da CONTRATADA;<br /><br /> (f) viabilizar integrações da Plataforma com Terceiros por meio de APIs;<br /><br /> e (g) promover resolução de problemas na Plataforma ou Aplicativo, verificação e proteção das Informações, da Plataforma ou Aplicativo contra erros, fraudes ou qualquer outro crime eletrônico.<br /><br />

12.6. A CONTRATADA poderá ter acesso as suas Informações para resolução de problemas, dúvidas e/ou quaisquer requisições do CLIENTE que exijam acesso a suas Informações.<br /><br />

12.7. O CLIENTE e cada um de seus Usuários poderão autorizar a CONTRATADA, a seu exclusivo critério, a divulgar mensagens enviadas por seus de forma escrita ou oral, por telefone, para uso em sites, jornais, revistas e outras campanhas, conforme venha a ser especificado na solicitação de autorização encaminhada pela CONTRATADA.<br /><br />

12.8. Não obstante as Informações fornecidas pelo CLIENTE estejam seguras nos termos deste Contrato, os Dados de Acesso à Plataforma são confidenciais e de responsabilidade exclusiva do CLIENTE e de seus USUÁRIOS.<br /><br />

12.9. Caso o CLIENTE ou qualquer de seus USUÁRIOS acredite que o sigilo de seus Dados de Acesso à Plataforma esteja ameaçado por qualquer razão, deverá imediatamente comunicar a CONTRATADA em quaisquer dos canais de atendimento disponíveis, sem prejuízo da alteração de senha imediata na própria Plataforma.<br /><br />

12.10. A CONTRATADA é responsável pela observância dos níveis legalmente requeridos para segurança na proteção das Informações, bem como pela adoção de meios e medidas técnicas para evitar a perda, acesso não autorizado ou subtração indevida das Informações.<br /><br />

12.10.1. A CONTRATADA envidará seus maiores esforços no sentido de não permitir que sejam violados os preceitos de integridade e segurança virtual do SALES JOURNEY. No entanto, diante do notório avanço tecnológico relativo à invasões e violações virtuais, a CONTRATADA não se responsabilizará por eventuais perdas, prejuízos, danos ou lucros cessantes que resultem da violação de sua segurança virtual por terceiros.<br /><br />

12.11. Para acessar alguns Serviços da Plataforma, o CLIENTE precisará acessar Software de Terceiros. O CLIENTE compreende e concorda que tais Informações serão compartilhadas com os Software de Terceiros, os quais possuem uma política própria para o tratamento de dados, conforme indicado em seus próprios termos de uso, A CONTRATADA não se responsabilizará pelo tratamento oferecido às Informações do CLIENTE por tais Softwares de Terceiros, cabendo ao CLIENTE, caso esteja em desacordo com a política de segurança de dados dos Softwares de Terceiros, cancelar a contratação do Serviço, ou adotar as medidas que creia sejam producentes para o caso.<br /><br />

13. LIMITAÇÃO DE RESPONSABILIDADE<br /><br />

13.1. Nenhuma das Partes será responsável por lucros cessantes, danos indiretos, especiais, incidentais, consequenciais ou punitivos em relação à outra.<br /><br />

13.2. As Partes entendem e concordam que o Licenciamento do SALES JOURNEY não configura qualquer relação que envolva vínculo empregatício entre as Partes, as quais obrigam-se por si próprias a arcar com eventual passivo de sua responsabilidade, e solicitar a exclusão da lide caso empregado da outra Parte venha a acioná-las judicialmente.<br /><br />

13.3. A Plataforma e os Serviços, inclusive os Serviços prestados por Softwares de Terceiros, são disponibilizados ao CLIENTE na condição "como está" e "como disponível", sem garantia expressa ou implícita, ou condição de qualquer tipo que garanta que: (a) as funções contidas na Plataforma e nos Serviços atendam às necessidades do CLIENTE;<br /><br /> (b) a Plataforma e os Serviços permanecerão disponíveis de forma ininterrupta ou livre de erros, (c) qualquer funcionalidade continuará disponível, (d) os defeitos na Plataforma e/ou nos Serviços serão corrigidos, ou (d) que haverá compatibilidade entre a Plataforma, aplicações ou serviços de terceiros.<br /><br />

13.4. Em nenhuma hipótese, a CONTRATADA responderá por eventuais prejuízos experimentados pelo CLIENTE em razão de: a) tomada de decisões com base nas informações disponibilizadas na Plataforma;<br /><br /> b) falhas no sistema de informática ou nos servidores que não tenham sido causados pela CONTRATADA;<br /><br /> c) interrupções da disponibilidade dos Serviços em razão dos eventos listados na Cláusula 10.1;<br /><br /> e) outros eventos de caso fortuito ou força maior, nos termos do artigo 393 do Código Civil Brasileiro.<br /><br />

13.5. A CONTRATADA exime-se de quaisquer responsabilidades relacionadas a eventuais danos e prejuízos de qualquer natureza que possam ser causados em virtude de acesso, interceptação, eliminação, alteração, modificação ou manipulação por terceiros não autorizados ou pelos próprios USUÁRIOS solicitados pelo CLIENTE, em arquivos e comunicações armazenados, transmitidos ou colocados à disposição por meio dos Serviços contratados.<br /><br />

13.6. Cabe exclusivamente ao CLIENTE a criação de senha quando do seu cadastro de USUÁRIO no SALES JOURNEY. O sigilo e responsabilidade pela utilização e atividades lá realizadas serão de inteira gestão do CLIENTE, de modo que este deverá manter sua conta atualizada, comunicando de pronto à CONTRATADA sobre qualquer problema relativo à quebra de segurança, bem como no caso de utilização não autorizada. O uso indevido por terceiros da senha do CLIENTE não trará qualquer responsabilidade à CONTRATADA, salvo se comprovada sua culpa ou dolo.<br /><br />

13.7. Em nenhum caso a CONTRATADA será responsável por danos pessoais ou qualquer prejuízo incidental, especial, indireto ou consequente, incluindo, sem limitação, perda de lucro, corrupção ou perda de dados, falha de transmissão ou recepção de dados, de correntes ou relacionados ao seu mau uso ou inabilidade do CLIENTE em usar o SALES JOURNEY, sem prejuízo do previsto na Cláusula 13.4.<br /><br />

13.8. Sob nenhuma circunstância a responsabilidade integral da CONTRATADA com relação ao CLIENTE por todos os danos indenizáveis, conforme regulada nesta Cláusula 13, excederá o correspondente a 20% (vinte por cento) dos valores pagos pelo CLIENTE à CONTRATADA no ano anterior à ocorrência do fato danoso.<br /><br />

14. PROPRIEDADE INTELECTUAL<br /><br />

14.1. A CONTRATADA é titular de todos os direitos intelectuais e patrimoniais sobre a Plataforma, incluindo todos os seus módulos e funcionalidades, marcas e domínios disponibilizados, bem como todos os demais direitos de propriedade intelectual vinculados aos Serviços contratados.<br /><br />

14.2. O CLIENTE será detentor de uma licença de uso limitada, não-exclusiva, intransferível, revogável, temporária e não passível de cessão, restando, portanto, autorizado o CLIENTE a tão somente utilizar as funcionalidades do SALES JOURNEY manifestamente contratadas.<br /><br />

14.3. Por meio deste Licenciamento, a CONTRATADA de nenhuma forma outorga ao CLIENTE quaisquer direitos, expressa ou implicitamente, sobre os direitos intelectuais (incluindo, sem limitação, qualquer invenção, descoberta, aperfeiçoamento ou know-how), direitos autorais ou sobre qualquer outro direito de propriedade intelectual de titularidade da CONTRATADA, independentemente da natureza pela qual foi adquirido ou concebido, antes ou após a data de vigência deste instrumento.<br /><br />

14.3.1. A CONTRATADA é detentora legítima da licença de Software de Terceiros incluídos nos Serviços licenciados ao CLIENTE e está autorizada a utilizar referidas licenças para a prestação dos Serviços oferecidos pela Plataforma.<br /><br />

14.4. O CLIENTE não poderá, em hipótese alguma, ceder, licenciar, sublicenciar, vender, dar em locação ou em garantia, doar, alienar de qualquer forma, transferir, total ou parcialmente, compartilhar senha, sob quaisquer modalidades, gratuita ou onerosamente, provisória ou permanentemente, os direitos de uso do SALES JOURNEY ou de quaisquer das suas funcionalidades, informações, dados, Softwares de Terceiros ou outras tecnologias que sejam de propriedade intelectual da CONTRATADA ou de terceiros.<br /><br />

14.5. Em nenhuma hipótese, o CLIENTE terá acesso ao código fonte da Plataforma ora licenciada, propriedade intelectual da CONTRATADA.<br /><br />

14.6. Todo o conteúdo do SALES JOURNEY, tutelado pelos direitos de Propriedade Intelectual, incluindo textos, imagens, gravações, códigos e outros, é pertencente à CONTRATADA, de forma que a sua utilização pelo CLIENTE está igualmente regulada por esta licença de uso e perdurará apenas durante a sua vigência.<br /><br />

14.6.1. Todo e qualquer conteúdo e material disponível e/ou exibido no SALES JOURNEY, o que inclui mas não se limita a gráficos, documentos, textos, imagens, ícones, fotografias, logos, gravações, software, marcas, programas de computador, bancos de dados, redes, arquivos e códigos-fonte, entre outros, é de propriedade exclusiva da CONTRATADA, que, por sua vez, reserva a si todos os direitos de propriedade intelectual, protegida, dessa forma, pelas leis de direitos autorais e outras regras, regulamentos e legislações brasileiras de propriedade intelectual aplicáveis, declarando a CONTRATADA que possui todas as licenças necessárias para sua reprodução ou utilização.<br /><br />

14.7. É vedado ao CLIENTE: a) Criar trabalhos derivados baseados nos Serviços;<br /><br /> b) Copiar ou exibir o conteúdo e layout do website e da sua aplicação em outro website;<br /><br />c) Copiar, apresentar ou reproduzir o conteúdo, ainda que parcial, da Plataforma para quaisquer fins, seja com objetivo comercial interno ou externo, salvo com autorização expressa da CONTRATADA;<br /><br /> d) Falar em nome da CONTRATADA ou utilizar seu nome, marca ou qualquer outro designativo, salvo quando expressamente autorizado pela CONTRATADA.<br /><br />

15. CONDIÇÕES GERAIS<br /><br />

15.1. Este instrumento vincula as Partes por si e por seus sucessores e cessionários mutuamente autorizados. As Partes, seus sucessores e cessionários autorizados deverão cumprir plenamente as obrigações aqui assumidas.<br /><br />

15.2. A natureza deste Licenciamento é individual e intransferível, sendo vedado ao CLIENTE, a cessão ou venda total ou parcial a terceiros dos direitos e obrigações ora assumidos, a qualquer título. A CONTRATADA está autorizada a ceder os direitos e obrigações do presente contrato a terceiros na hipótese e de venda, aquisição, fusão, reorganização societária da CONTRATADA, independente de autorização prévia do CLIENTE.<br /><br />

15.3. Serão considerados documentos anexos ao presente instrumento eventuais e-mails que tratem das validações necessárias à execução do objeto do presente instrumento, nos termos aqui definidos e como tais deverão ser considerados partes integrantes deste instrumento. Em caso de conflito entre o disposto em quaisquer dos documentos, à exceção de aspectos técnicos, prevalecerão os termos aqui presentes. De igual forma, integram o presente, como já dito, a proposta que particularizou a negociação.<br /><br />

15.4. O CLIENTE declara e garante à CONTRATADA que é pessoa física ou pessoa jurídica com plena capacidade para contratar, e detém toda autoridade e poderes necessários para realizar dessa contratação.<br /><br />

15.5. As partes concordam que o presente contrato e qualquer documento bancário emitido com base neste instrumento, constituem títulos executivos extrajudiciais, a ensejar execução forçada, nos termos da legislação Processual Civil.<br /><br />

15.6. A tolerância das Partes quanto a eventuais descumprimentos de disposições do presente instrumento, bem como dos demais Termos e eventuais anexos que regerão a relação jurídica aqui celebrada não constituirá renúncia aos direitos que são mutuamente ora conferidos, nem poderá ser tida como novação, permanecendo em vigor as demais cláusulas deste instrumento da forma em que foram originalmente pactuadas.<br /><br />

15.7. Se qualquer disposição deste instrumento for considerada nula, anulável, inválida, inoperante, nenhuma outra disposição aqui presente será afetada como consequência e, da mesma forma, todas as demais disposições deverão permanecer em total vigor e efeito como se tal disposição nula, anulável, inválida ou inoperante não tivesse sido aqui incluída. Se a aplicação de qualquer disposição aqui contida, com relação a qualquer sujeito ou circunstância for inválida ou inexequível, uma disposição adequada e equitativa deverá substituí-la de forma a fazer valer este instrumento, na máxima extensão possível para que seja válida e exequível, e de acordo com a intenção e o objetivo de tal disposição inválida ou inexequível.<br /><br />

15.8. Todos os avisos, notificações, solicitações ou comunicações ocorridas na constância da relação jurídica ora contratada, assim como comunicações envolvendo cada uma das Partes, inclusive para fins de dar ou receber informações, se para a CONTRATADA, deverão ocorrer no SALES JOURNEY, por-mail ou Chat e se para o CLIENTE, serão dadas por meio do e-mail cadastrado no SALES JOURNEY.<br /><br />

15.9. O CLIENTE expressamente concorda que este instrumento poderá ser a qualquer tempo modificado unilateralmente pela CONTRATADA, a seu exclusivo critério, sem a necessidade de aviso prévio, exceto em caso de modificações que a CONTRATADA considere materiais. A CONTRATADA compromete-se, no entanto, a comunicar a alteração contratual realizada por aviso enviado ao e-mail cadastrado do CLIENTE. A partir da data de publicação da versão atualizada do Contrato no SALES JOURNEY, os Serviços passarão a ser regulados pelos termos e condições do Contrato atualizado, cabendo ao CLIENTE que vier a discordar de referida alteração, solicitar a rescisão contratual, nos moldes da Cláusula 8.1. a continuidade na utilização dos Serviços serão consideradas como aceitação das novas condições contratuais.<br /><br />

15.10. Este instrumento é regido e será interpretado de conformidade com as leis da República Federativa do Brasil.<br /><br />

15.11. Fica eleito o foro competente da Comarca de São Paulo, Estado de São Paulo, como o único competente para a resolução de disputas e/ou controvérsias decorrentes do presente instrumento, com exclusão de qualquer outro, por mais privilegiado que seja.<br /><br />

INSTRUMENTO ELABORADO EM 02/2/2021 COLOCADO EM REGISTRO PERANTE O OFICIAL DE NOTAL DA CAPITAL DE SÃO PAULO CUJOS DADOS SERÃO AMPLAMENTE PUBLICITADOS E INDEPENDENTEMENTE, DISPONIBILIZADOS JUNTO AO SITE DA CONTRATADA.</p>


      </section>


      </div>
    </main>
  );
}

export default Termos;
