
function Beneficios_cards() {
  return (
    <div className="beneficios_cards">


      <div className="row cards-beneficios">


        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="100" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Mobile first</h5>
              <p className="card-text">Gerencia todo processo de venda onde estiver.</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="200" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Integração</h5>
              <p className="card-text">Uma robusta camada de APIs, eventos e webhooks (callbacks).</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="300" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Auditoria</h5>
              <p className="card-text">Processos auditáveis e proteção de dados em toda jornada de vendas.</p>
            </div>
          </div>
        </div>



        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="400" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Compliance</h5>
              <p className="card-text">Possibilidade de criar bloqueios e aprovações em diversas informações, afim de prover validações do processo.</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="500" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Hierarquia Comercial</h5>
              <p className="card-text">Uma simples e intuitiva ferramenta para definir propriedade de dados e carteiras.</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="600" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Políticas Comerciais</h5>
              <p className="card-text">Defina regras de exibição de clientes, produtos, formas de pagamento etc., na criação do pedido de venda.</p>
            </div>
          </div>
        </div>



        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="700" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Plataforma SaaS</h5>
              <p className="card-text">Foque na sua operação. Nós cuidamos da tecnologia.</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="800" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Layout Customizável</h5>
              <p className="card-text">Configuração de cores e logotipo da sua empresa.</p>
            </div>
          </div>
        </div>

        <div className="col-sm-4" data-aos="flip-left" data-aos-duration="700" data-aos-delay="900" >
          <div className="card">
            <div className="card-body">
              <h5 className="card-title">Suporte</h5>
              <p className="card-text">Suporte prioritário. Customer success (+100 usuários). </p>
            </div>
          </div>
        </div>



      </div>


    </div>
  );
}

export default Beneficios_cards;
